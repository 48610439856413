import React from 'react'
import {Box, Container, Flex} from 'theme-ui'
import ContentText from '@solid-ui-components/ContentText'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'

const ContentBlock03 = ({content: {collection}, reverse}) => (
    <Container>
        {collection && (
            <Flex sx={{
                flexWrap: `wrap`,
                m: -3,
                gap: '50px',
                flexDirection: 'column',
            }}>
                {collection.map(({text}, index) => (
                    <Box key={`heading-${index}`}>
                        <ContentText content={text}/>
                    </Box>
                ))}
            </Flex>)}
    </Container>
)

export default WithDefaultContent(ContentBlock03)
