/**
 *
 * These styles are solely for adding [background images] or
 * [background colors] to blocks.
 *
 */

const PrivacyStyles = {
  heroContainer: {
    bg: `beta`,
    pt: [6, 7],
    pb: [5, 6],
    px: [4, 0]
  }
}

export default PrivacyStyles
